import CustomError from './custom-error';

export default class NotFoundError extends CustomError {
  httpCode = 404;

  constructor(...params: string[]) {
    super(...params);

    this.name = 'Not Found';
    this.message = params[0] || 'Resource not found';
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}
