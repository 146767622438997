import React from 'react';
import type { FormikProps } from 'formik';
import Input, { InputProps } from './Input';

export type FormikInputProps<T extends Record<string, string | number | string[]>> = {
  formik: FormikProps<T>,
  name: keyof T & string,
} & Partial<InputProps>;

const FormikInput = <T extends Record<string, string | number | string[]>>({
  formik, name, useErrorLabel, ...rest
}: FormikInputProps<T>) => (
  <Input
    error={(formik.touched[name] && formik.errors[name]) as string | undefined}
    id={name}
    name={name}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    {...rest}
    value={formik.values[name]}
    useErrorLabel={useErrorLabel}
  />
  );
export default FormikInput;
