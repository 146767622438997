import CustomError from './custom-error';

class ForbiddenError extends CustomError {
  httpCode = 403;

  constructor(...params: string[]) {
    super(...params);

    this.name = 'Forbidden';
    this.message = params[0] || 'The user does not have the necessary permissions';
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export default ForbiddenError;
