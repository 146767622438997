import CustomError from './custom-error';

class BadRequestError extends CustomError {
  httpCode = 400;

  constructor(...params: string[]) {
    super(...params);
    this.name = 'Bad Request';
    this.message = params[0] || 'Invalid request';
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }
}

export default BadRequestError;
