import { twMerge } from 'tailwind-merge';
import ErrorText from '@/src/components/Typography/ErrorText';

export type InputProps = {
  className?: string;
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  error?: string | boolean,
  useErrorLabel?: boolean,
} & React.ComponentPropsWithoutRef<'input'>;

const activeClasses = 'border-teal-60 ring-teal-20';
const disabledClasses = 'border-mauve-20';

const Input = ({
  className, startIcon, endIcon, disabled, error, useErrorLabel, ...props
}: InputProps) => (
  <>
    <div className={twMerge(
      'relative flex w-full border-2  rounded-full focus-within:ring-4',
      disabled ? disabledClasses : activeClasses,
      error ? 'ring-rose-60 border-rose-100' : '',
      className,
    )}
    >
      {startIcon && (<div className="py-3 pl-4 flex justify-center items-center">{startIcon}</div>)}
      <input
        {...props}
        className={twMerge(
          'w-full flex-1  rounded-full outline-none px-4 py-[14px] text-blue-100 text-base font-museo-300 disabled:bg-white',
          endIcon && 'rounded-r-none pr-0',
          startIcon && 'pl-2 rounded-l-none',
        )}
        disabled={disabled}
      />
      {endIcon && (<div className="flex items-center gap-2 justify-center pr-1">{endIcon}</div>)}
    </div>
    {(useErrorLabel && error) && (
      <ErrorText className="mt-2">{error}</ErrorText>
    )}
  </>
);
export default Input;
