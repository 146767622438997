import Link from 'next/link';

type UnderlinedLinkProps = {
  href: string,
  external?: boolean,
  className?: string,
  children: React.ReactNode,
} & React.ComponentPropsWithoutRef<'a'>;

const UnderlinedLink = ({
  href, children, external = false, className, ...props
}: UnderlinedLinkProps) => (
  <span className="border-b-2 pb-1 border-b-green-60 text-blue-100 hover:border-teal-60 whitespace-nowrap">
    {external
      ? <a className={`text-sm ${className}`} target="_blank" rel="noreferrer" href={href} {...props}>{children}</a>
      : <Link className={`text-sm ${className}`} href={href} {...props}>{children}</Link>}
  </span>
);

export default UnderlinedLink;
