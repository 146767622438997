import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import Input from './Input';

export type CheckboxProps = {
  children?: React.ReactNode,
  name: string,
  error?: string,
  variant?: 'sm' | 'md',
  checked: boolean,
} & React.ComponentPropsWithoutRef<'input'>;

const Checkbox = ({
  id, name, className, children, checked = false, variant = 'md', ...rest
}: CheckboxProps) => {
  const boxSize = variant === 'md' ? 'h-[24px] min-w-[24px]' : 'h-[16px] min-w-[16px]';
  const tickSize = variant === 'md' ? 17 : 12;

  return (
    <label htmlFor={id} className={twMerge('flex items-start', className)}>
      <Input
        {...rest}
        error={!!rest.error}
        type="checkbox"
        id={id}
        name={name}
        className="hidden"
      />
      <div
        tabIndex={0}
        role="checkbox"
        aria-checked
        className={`tabindex='0' flex items-center justify-center ${boxSize} rounded mr-2 cursor-pointer bg-mauve-20 hover:opacity-60`}
      >
        {checked && <Image src="/assets/icons/checkmark.svg" alt="Groundshop" width={`${tickSize}`} height={12} />}
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
