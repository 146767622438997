type StepItemProps = {
  text: string,
  pass: boolean,
  check: React.ReactNode,
  cross: React.ReactNode,
};

type StepCheckerProps = {
  list: { text: string, pass: boolean }[],
  isVisible: boolean,
  check: React.ReactNode,
  cross: React.ReactNode,

};

const StepItem = ({
  text, pass, check, cross,
}: StepItemProps) => (
  <div className={`${pass ? 'h-[0px] delay-500 m-0' : 'h-[34px] delay-0 mb-2 mt-2'} overflow-hidden flex items-center`}>
    {pass ? check : cross}
    <pre className="font-museo-500 text-sm text-blue-100 ml-2 whitespace-pre">
      {text}
    </pre>
  </div>
);

const StepChecker = ({
  isVisible, list, check, cross,
}: StepCheckerProps) => {
  const allPass = list.every(({ pass }) => pass);
  const renderList = () => list.map(({ text, pass }) => (
    <StepItem text={text} pass={pass} check={check} cross={cross} key={text} />
  ));

  return (
    <div
      className={`
    ${isVisible
        ? 'px-3 py-2 border-2 border-teal-60 -mt-5 mb-6'
        : 'p-0 border-0 h-0'
    } bg-white w-full overflow-hidden border-teal-60 rounded-3xl top-[75%] flex flex-col shadow-xl
    `}
    >
      <div className="mb-2 mt-2 h-[24px] overflow-hidden flex items-center">
        {allPass ? check : cross}
        <span className="font-museo-500 text-sm text-blue-100 ml-2">
          Password strength:
          {' '}
          {allPass
            ? <span className="text-green-200">strong</span>
            : <span className="text-rose-100">weak</span>}
        </span>
      </div>
      {renderList()}
    </div>
  );
};

export default StepChecker;
