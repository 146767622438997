abstract class CustomError extends Error {
  abstract httpCode: number;

  constructor(...params: string[]) {
    super(...params);

    Object.setPrototypeOf(this, CustomError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
  }

  public get status() {
    return this.httpCode;
  }

  public get json() {
    return {
      status: this.status,
      name: this.name,
      message: this.message,
    };
  }
}

export default CustomError;
