import React from 'react';
import type { FormikProps } from 'formik';
import Checkbox, { CheckboxProps } from './Checkbox';

export type FormikCheckboxProps<T extends Record<string, string | number | string[]>> = {
  formik: FormikProps<T>,
  name: keyof T & string,
  className?: string,
} & Partial<CheckboxProps>;

const FormikCheckbox = <T extends Record<string, string | number | string[]>>({
  formik, name, children, ...rest
}: FormikCheckboxProps<T>) => (
  <Checkbox
    error={(formik.touched[name] && formik.errors[name]) as string | undefined}
    id={name}
    name={name}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    {...rest}
    checked={!!(formik.values[name] as string[]).length}
  >
    {children}
  </Checkbox>
  );
export default FormikCheckbox;
