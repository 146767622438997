import { twMerge } from 'tailwind-merge';

type ErrorTextProps = {
  className?: string,
  children: React.ReactNode
};

const ErrorText = ({ className, children }: ErrorTextProps) => (
  <span className={twMerge('text-sm text-rose-400 font-museo-500 inline-block', className)}>
    {children}
  </span>
);

export default ErrorText;
