import CustomError from './custom-error';

class InternalServerError extends CustomError {
  httpCode = 500;

  constructor(...params: string[]) {
    super(...params);

    this.name = 'InternalServerError';
    this.message = params[0] || 'An unknown error has occurred';
    Object.setPrototypeOf(this, InternalServerError.prototype);
  }
}

export default InternalServerError;
